<template>
  <div
    :style="cssMaxLinesVars"
  >
    <img
      v-if="picture"
      :src="picture"
      loading="lazy"
      class="review-picture"
    >
    <div :class="['review', { 'detached-content': contentDetached, 'no-avatar': !showAvatar }]">
      <div class="avatar-area">
        <ZAvatar
          v-if="showAvatar"
          :to="
            reviewerId
              ? localePath({ name: 'profile-id', params: { id: reviewerId }, query: { cta: 'reviews' } })
              : undefined
          "
          :src="avatar ?? ''"
          :alt="initials"
          :text="initials"
          :size="avatarSize"
          fixed-src
          :variant="avatar ? 'primary-light' : 'primary'"
        />
      </div>

      <div class="meta">
        <div class="mb-1">
          <span class="user-name">{{ userName }}</span>
          <div
            v-if="position"
            class="position"
          >
            {{ position }}
          </div>
          <span
            v-if="reviewDate"
            class="date ml-1"
          >{{ reviewDate }}</span>
        </div>
        <div v-if="rating">
          <ZRating
            :value="formatWithPrecision(rating)"
            icon-variant="highlight"
            bold
            round-average
          />
          <span
            v-if="isDelivered"
            class="ml-2"
          ><fa
            :icon="['fas', 'truck-fast']"
            class="mr-1"
          />{{ t('hostDelivered') }}</span>
        </div>
      </div>
      <div
        v-if="!isAutomatic"
        ref="clampedDiv"
        :class="['content', { 'max-lines': maxLines }]"
      >
        {{ content }}
      </div>
      <div
        v-else
        :class="['content', { 'max-lines': maxLines }]"
      >
        {{ t('ReviewContent', daysBeforeTrip || 0) }}
      </div>
      <div
        v-if="isClamped"
        class="see-more-button"
      >
        <ZButton
          link
          variant="primary"
          @click="emit('see-more')"
        >
          See more
        </ZButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ReviewComponentProps } from '~/types/style-guide'

const clampedDiv = ref<HTMLDivElement>()

const isClamped = computed(() => {
  if (!clampedDiv.value) {
    return false
  }

  // scrollHeight is the total height of the content, whilst clientHeight is the height of the visible content ;)
  return clampedDiv.value.scrollHeight > clampedDiv.value.clientHeight
})

const emit = defineEmits<{
  'see-more': []
}>()

const props = withDefaults(defineProps<ReviewComponentProps>(), {
  contentDetached: false,
  avatarSize: 'sm',
  showAvatar: true,
  isAutomatic: false,
  isDelivered: false,
  daysBeforeTrip: 0,
})

const { t } = useI18n()
const localePath = useLocalePath()

const cssMaxLinesVars = computed(() => {
  if (!props.maxLines) {
    return {}
  }
  return {
    '--max-lines': props.maxLines,
  }
})

const initials = computed(() => formatNameAsInitials(props.reviewerFirstName, props.reviewerLastName))
const userName = computed(() => formatNameAsUsername(props.reviewerFirstName, props.reviewerLastName))

const reviewDate = computed(() => props.createdAt ? formatAsMonthYear(props.createdAt) : null)
</script>

<style lang="scss" scoped>
.review {
  display: grid;
  grid-gap: 0.5rem 1rem;
  grid-template-areas:
    'avatar meta'
    'avatar content'
    'avatar see-more';
  grid-template-columns: min-content;

  &.no-avatar {
    display: flex;
    flex-direction: column;
  }

  .avatar-area {
    grid-area: avatar;
  }

  .meta {
    grid-area: meta;
  }

  .see-more-button {
    grid-area: see-more;
  }

  .content {
    grid-area: content;
    word-break: break-word;

    &.max-lines {
      display: -webkit-box;
      width: 100%;
      line-clamp: var(--max-lines);
      -webkit-line-clamp: var(--max-lines);
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &.detached-content {
    grid-template-areas:
      'avatar meta'
      'content content'
      'see-more see-more';
  }
}

.user-name {
  @include semi-bold;
  color: getColor('primary-500');
}

.date,
.position,
.content {
  color: getColor('primary-350');
}

.review-picture {
  width: 100%;
  border-radius: 16px;
  border: 1px solid getColor('primary-100');
  margin-bottom: 1.25rem;
}
</style>

<i18n lang="json">
{
  "en": {
    "hostDelivered": "Delivered by host",
    "ReviewContent": "The host canceled one day before this reservation. This is an automated posting. | The host canceled {n} days before this reservation. This is an automated posting."
  },
  "fr": {
    "hostDelivered": "Livré par l’hôte",
    "ReviewContent": "L’hôte a annulé un jour avant cette réservation. Ceci est une notification automatisée. | L’hôte a annulé {n} jours avant cette réservation. Ceci est une notification automatisée."
  }
}
</i18n>
